import React, {useContext} from 'react'
import {route} from 'next/dist/next-server/server/router'
import {useRouter} from 'next/router'
import {USBGrid, USBColumn} from '@usb-shield/react-grid'
import PageTitle from '../../atoms/PageTitle/PageTitle'
import {
  getPageTitle,
  isNullOrEmpty,
  apiURLs,
  getBackgroundImageURL,
  isLoginEnabled,
  atmConnectVersion
} from '../../../utils/Utility'
import {AuthContext} from '../../../context/AuthContext'


const Content = ({children, pageTitleClasses=null, paddingColumnCount = 2, contentAreaClassName = "contentArea", contentAreaColumnClassName = "ContentColumn"}) => {
  const router = useRouter()
  const authContext = useContext(AuthContext)

  const isAuthenticated = () => {
    // const iPlanetDirectoryProCookie = Cookies.get(getIPlanetProCookieName())
    // return iPlanetDirectoryProCookie || (authContext && authContext.isAuthenticated)
    const isAuth = authContext && authContext.isAuthenticated
    // console.log(`isAuth = '${isAuth}'`)
    return isAuth
  }


  const getPageHeading = () => {
    let heading = 'Login'
    if (isAuthenticated()) heading = router.route ? getPageTitle(router.route) : 'Login'
    // if internal user we don't show login page
    if (!isLoginEnabled() && (heading === 'Login' || heading === 'Home')) heading = ''
    return heading
  }
  const isFooterLink = () => {
    const footerLinks = ['Help', 'Contact Us', 'Privacy & Security']
    return footerLinks.find(o => o === getPageTitle(router.route))
  }
  const getTitle = () => {
    const heading = getPageHeading()
    return isFooterLink() || heading === 'Login' ? '' : heading
  }


  return (
    <>
      {/* Page Title  */}
      <USBGrid addClasses={`white-frame pageTitle ${pageTitleClasses}`}>
        {(paddingColumnCount > 0) && <USBColumn largeSpan={paddingColumnCount} />}
        <USBColumn largeSpan={16 - 2 * paddingColumnCount} addClasses={!paddingColumnCount ? 'left' : "middle"}>
          <PageTitle headerText={getTitle()} />
        </USBColumn>
        {(paddingColumnCount > 0) && <USBColumn largeSpan={paddingColumnCount} />}
      </USBGrid>
      {/* Main Content Area  */}
      <section className={contentAreaClassName}>
        <USBGrid>
          {(paddingColumnCount > 0) && <USBColumn largeSpan={paddingColumnCount} />}
          <USBColumn largeSpan={16 - 2 * paddingColumnCount} addClasses={contentAreaColumnClassName}>
            {typeof window !== 'undefined' && children}
          </USBColumn>
          {(paddingColumnCount > 0) && <USBColumn largeSpan={paddingColumnCount} />}
        </USBGrid>
      </section>
    </>
  )
}

export default Content
import React from 'react'
import { PropTypes } from 'prop-types'
import Head from 'next/head'

/**
 * Returns page header and title with necessary styles
 * @param {string} headerText
 */
const PageTitle = ({ headerText = '', addClasses }) => {
  const title = `ATMConnect${headerText !== '' ? ' - ' : ' - Home'}${headerText}`

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <p className={`page-header ${addClasses}`}>{headerText}</p>
    </>
  )
}

PageTitle.propTypes = {
  headerText: PropTypes.string.isRequired,
  addClasses: PropTypes.string
}

PageTitle.defaultProps = {
  addClasses: ''
}

export default PageTitle

import React, { useReducer } from 'react'
import { PropTypes } from 'prop-types'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { getSharedCookieDomain, isLoginEnabled } from '../utils/Utility'

/**
 * Initial State being used by useReducer (state management)
 */
const initialState = {
  isAuthenticated: false,
  companyId: '',
  companyName: '',
  userId: '',
  firstName: '',
  lastName: '',
  userRoles: '',
  sessionTimeLeft: '',
  isUserTimedOut: false,
  isUserLoggedOut: false,
  isFormChanged: false,
  token: '',
  applicationId: '',
  showTimings: false,
  features: []
}

/**
 * Returns authenication context with state, login & logout event handlers
 */
export const AuthContext = React.createContext({
  ...initialState,
  login: () => { },
  logout: () => { },
  updateState: () => { },
  toggleShowTimings: () => {}
})

/**
 * Reducer function, which will be supplied to userReducer
 * Preserves the old state, and updates only the supplied new state values
 * @param {object} prevState - previous state object
 * @param {object} newState - current state object
 */
const authReducer = (prevState, newState) => {
  return {
    ...prevState,
    ...newState,
  }
}

/**
 * Returns authentication context provider with state, login & logout event handlers
 * from AuthContext we created from React.Context
 * AuthContextProvider gives the ability to wrap a component so we can make use of context inside the component
 * Context is the easy way to make the global variables available to the derived component/page
 */
const AuthContextProvider = props => {
  const [authState, setAuthState] = useReducer(authReducer, initialState)
  const router = useRouter()

  /**
   * Login handler event. Derived components can raise this event for updating state for the context.
   * @param {object} object - object contains props to update state
   */
  const loginHandler = ({
    isAuthenticated,
    companyId,
    userId,
    firstName,
    lastName,
    companyName,
    userRoles,
    token,
    applicationId,
    features

  }, redirect = true) => {
    setAuthState({
      isAuthenticated,
      companyId,
      userId,
      firstName,
      lastName,
      companyName,
      userRoles,
      token,
      applicationId,
      features
    })
    if (redirect) {
      if (router.route.startsWith('/UserProfile') || router.route.startsWith('/UserManager') || router.route.startsWith('/CompanyManager'))
        router.push(router.route)
      else
        router.push('/')
    }
  }

  const clearSession = () => {
    const cookieDomain = getSharedCookieDomain() === 'localhost' ? null : getSharedCookieDomain()
    Cookies.remove('amlbcookie', { path: '', domain: cookieDomain })
    Cookies.remove('AlternateID', { path: '', domain: cookieDomain })
    localStorage.clear()
  }

  /**
   * Login out handler event. Derived components can raise this event for logging out user.
   * Resets state to initial state and logs out user.
   */
  const logoutHandler = ({ isUserTimedOut, isUserLoggedOut }) => {
    clearSession()
    setAuthState({ ...initialState, isUserTimedOut, isUserLoggedOut })
    if (isLoginEnabled()) router.push('/Login')
    else {
      window.close()
      router.push('/')
    }
  }

  const updateState = value => {
    setAuthState({ ...value })
  }
  const toggleShowTimings = () =>{
    setAuthState( { showTimings: !authState.showTimings} )
  }
  /**
   * Returns AuthContext Provider.
   * Refer to _app.jsx and UserManager\index.jsx for usage.
   */
  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login: loginHandler,
        logout: logoutHandler,
        clearSession,
        updateState,
        toggleShowTimings
      }}
    >
      {
        // eslint-disable-next-line react/destructuring-assignment
        props.children
      }
    </AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.node
}

AuthContextProvider.defaultProps = {
  children: null
}

export default AuthContextProvider
